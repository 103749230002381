@charset "UTF-8";
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

.container {
  margin: 0 auto;
  width: 960px;
}

.col2 {
  float: left;
  margin: 0 10px;
  width: 140px;
}

.col3 {
  float: left;
  margin: 0 10px;
  width: 220px;
}

.col4 {
  float: left;
  margin: 0 10px;
  width: 300px;
}

.col5 {
  float: left;
  margin: 0 10px;
  width: 380px;
}

.col6 {
  float: left;
  margin: 0 10px;
  width: 460px;
}

.col7 {
  float: left;
  margin: 0 10px;
  width: 540px;
}

.col8 {
  float: left;
  margin: 0 10px;
  width: 620px;
}

.col9 {
  float: left;
  margin: 0 10px;
  width: 700px;
}

.col10 {
  float: left;
  margin: 0 10px;
  width: 780px;
}

.col11 {
  float: left;
  margin: 0 10px;
  width: 860px;
}

.col12 {
  float: left;
  margin: 0 10px;
  width: 940px;
}

.pre1 {
  padding-left: 80px;
}

.pre2 {
  padding-left: 160px;
}

.pre3 {
  padding-left: 240px;
}

.pre4 {
  padding-left: 320px;
}

.pre5 {
  padding-left: 400px;
}

.pre6 {
  padding-left: 480px;
}

.pre7 {
  padding-left: 560px;
}

.pre8 {
  padding-left: 640px;
}

.pre9 {
  padding-left: 720px;
}

.pre10 {
  padding-left: 800px;
}

.pre11 {
  padding-left: 880px;
}

.suf1 {
  padding-right: 80px;
}

.suf2 {
  padding-right: 160px;
}

.suf3 {
  padding-right: 240px;
}

.suf4 {
  padding-right: 320px;
}

.suf5 {
  padding-right: 400px;
}

.suf6 {
  padding-right: 480px;
}

.suf7 {
  padding-right: 560px;
}

.suf8 {
  padding-right: 640px;
}

.suf9 {
  padding-right: 720px;
}

.suf10 {
  padding-right: 800px;
}

.suf11 {
  padding-right: 880px;
}

.colr {
  float: right;
  margin: 0 10px;
}

.alpha {
  margin-left: 0;
}

.omega {
  margin-right: 0;
}

.clear {
  clear: both;
  width: 100%;
}

.center {
  float: none;
  margin: 0 auto;
}

a img {
  border: 0;
}

.head {
  background-color: #F6F6F6;
}

.logo {
  float: left;
  width: 140px;
  height: 40px;
  padding-top: 17px;
}
.logo a {
  display: block;
  width: 140px;
  height: 40px;
  text-indent: 200px;
  line-height: 200px;
  overflow: hidden;
  background-image: url("../img/logo-bam-280x80.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 140px 40px;
  background-position: 0 0;
}

@media (min-width: 1024px) {
  .head {
    height: 70px;
  }
  .head .col12 {
    position: relative;
  }
}
@media (min-width: 120px) and (max-width: 1023px) {
  .head {
    height: 60px;
  }

  .logo {
    float: left;
    width: 120px;
    height: 34px;
    padding-top: 14px;
  }
  .logo a {
    background-size: 120px 34px;
  }
}
.breadcrumbs {
  padding: 0;
  background-color: #F6F6F6;
}

.RadSiteMap .rsmFlow {
  padding: 0 !important;
  color: #ABABAB !important;
}
.RadSiteMap .rsmFlow a {
  color: #C1C6C2;
}

.RadSiteMap_Default .sfNoBreadcrumbNavigation .rsmLink {
  color: #ABABAB !important;
}

.RadSiteMap_Default .sfBreadcrumbNavigation .rsmLink {
  color: #FF6602 !important;
}

.RadSiteMap_Default .rsmLink {
  font-size: 15px;
  font-weight: normal;
  border: none;
}
.RadSiteMap_Default .rsmLink:hover {
  border: none !important;
  background: none !important;
  border-radius: 0;
  text-decoration: underline;
}

.top-search {
  overflow: hidden;
}
.top-search .form-search {
  height: 32px;
  position: relative;
  width: 190px;
}
.top-search .field {
  padding: 0;
}
.top-search label {
  display: none;
}
.top-search input[type=search] {
  width: 100%;
  height: 32px;
  padding: 5px 30px 5px 10px;
  border-radius: 18px;
  border: 1px solid #C1C6C2;
  box-sizing: border-box;
}
.top-search input[type=search]:focus {
  border-color: #666666;
  outline: none;
}
.top-search input[type=submit].submit-search {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  padding: 0 !important;
  border-radius: 12px;
  border: 0;
  line-height: 200px;
  text-indent: 200px;
  overflow: hidden;
  cursor: pointer;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-repeat: no-repeat;
  background-position: -113px -24px;
  background-color: transparent;
}
.top-search input[type=submit].submit-search:focus {
  outline: 0;
}

.search-open .top-search {
  width: 200px;
}
.search-open .btn-search a {
  background-position: -41px -60px;
}

@media (min-width: 1024px) {
  .top-search {
    position: absolute;
    top: 0;
    width: 0;
    right: 36px;
    padding: 18px 0;
    transition: all 120ms linear;
  }
  .top-search form {
    position: relative;
    width: 190px;
  }
  .top-search fieldset {
    height: 32px;
  }

  .search-open .top-search {
    width: 200px;
  }
  .search-open .btn-search a {
    background-position: -41px -54px;
  }
}
@media (min-width: 680px) and (max-width: 1023px) {
  .top-search {
    position: absolute;
    top: 0;
    width: 0;
    right: 46px;
    padding: 14px 0;
    transition: all 120ms linear;
  }
  .top-search form {
    position: relative;
    width: 190px;
  }
  .top-search fieldset {
    height: 32px;
  }

  .search-open .top-search {
    width: 200px;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .top-search {
    width: 100%;
    top: 328px;
    left: 0;
    position: absolute;
    z-index: 16;
    height: 52px;
    clear: both;
    background-color: #d9e3dd;
  }
  .top-search fieldset.form-search {
    padding: 10px;
    height: 52px;
    width: 100%;
    box-sizing: border-box;
  }
  .top-search input[type=search].submit-search {
    width: 100%;
  }
  .top-search input[type=submit].submit-search {
    top: 14px;
    right: 14px;
  }
  .top-search .submit-search {
    top: 14px;
    right: 14px;
  }

  .menu-closed .top-search {
    height: 0;
    overflow: hidden;
  }
}
.sfsearchBox {
  margin: 0;
  padding: 0;
  border: 0;
}
.sfsearchBox input[type=text] {
  height: 39px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #C1C6C2;
  float: left;
  margin: 0 10px 0 0;
  box-sizing: border-box;
}
.sfsearchBox input[type=text]:focus {
  border-color: #666666;
  outline: none;
}
.sfsearchBox input[type=submit] {
  float: left;
  position: relative;
  padding: 10px 20px 10px 40px;
  width: 106px;
  background-image: url("../img/sprite-lr.png");
  background-repeat: no-repeat;
  background-size: 260px 300px;
  background-position: -104px -72px;
  box-sizing: border-box;
}
.sfsearchBox input[type=submit]:focus {
  outline: none;
  background-color: #9e9e9e;
}
.sfsearchBox .field {
  padding: 0;
}

@media (min-width: 1024px) {
  .sfsearchBox input[type=text] {
    width: 340px;
  }
}
@media (min-width: 1360px) {
  .sfsearchBox input[type=text] {
    width: 450px;
  }
}
@media (min-width: 680px) and (max-width: 1023px) {
  .sfsearchBox input[type=text] {
    width: 60%;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .sfsearchBox {
    position: relative;
    margin: 0 0 10px 0;
  }
  .sfsearchBox input[type=text] {
    width: 100%;
    padding-right: 120px;
  }
  .sfsearchBox input[type=submit] {
    position: absolute;
    top: 1px;
    right: 1px;
    border-radius: 0 3px 3px 0;
  }
}
.menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu li {
  margin: 0;
  padding: 0;
}
.menu li:hover > a, .menu li.active > a {
  background-color: #E8EEEA;
}
.menu a {
  display: block;
  color: #00B21D;
  text-decoration: none;
}
.menu a:hover {
  background-color: #E8EEEA;
}

.submenu {
  display: none;
}
.submenu ul {
  display: none !important;
}

.btn-search {
  width: 36px;
  overflow: hidden;
}
.btn-search a {
  line-height: 200px;
  text-indent: 200px;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-position: -104px -60px;
  background-repeat: no-repeat;
  background-color: #EBEBEB;
}

@media (min-width: 1024px) {
  .menu {
    float: right;
    transition: all 100ms linear;
  }
  .menu li {
    float: left;
    height: 70px;
    position: relative;
    transition: all 100ms linear;
  }
  .menu li:hover .submenu {
    display: block;
  }
  .menu a {
    height: 70px;
    line-height: 68px;
    padding: 0 15px;
    transition: all 100ms linear;
  }

  .submenu {
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    height: auto;
    width: 200px;
    font-size: 0.9em;
    list-style: none;
  }
  .submenu li {
    margin: 0;
    padding: 0;
    height: auto;
    float: none;
  }
  .submenu a {
    display: block;
    height: auto;
    line-height: 20px;
    padding: 10px 15px;
    color: #666666;
    background-color: #F6F6F6;
  }
  .submenu a:hover {
    background-color: #E8EEEA;
    color: #00B21D;
  }

  .btn-menu {
    display: none;
  }

  .btn-search a {
    background-position: -104px -54px;
  }
}
@media (min-width: 680px) and (max-width: 1023px) {
  .menu {
    float: right;
  }
  .menu li {
    float: left;
  }
  .menu a {
    height: 60px;
    line-height: 58px;
    padding: 0 5px;
    font-size: 0.9em;
  }

  .btn-menu {
    display: none;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .head .container,
.head .col12 {
    margin: 0;
    padding: 0;
  }

  .logo {
    margin-left: 10px;
  }

  .btn-menu {
    color: #00B21D;
    line-height: 60px;
    text-decoration: none;
    display: block;
    float: right;
    margin-right: 10px;
  }

  .btn-search {
    display: none;
  }

  .main-nav {
    height: 380px;
    transition: all 100ms ease-out;
  }
  .main-nav.closed {
    height: 0;
  }

  .menu {
    position: absolute;
    top: 60px;
    z-index: 15;
    width: 100%;
    clear: both;
    height: 256px;
    overflow: hidden;
    background-color: #E8EEEA;
    padding: 6px 0;
    transition: all 100ms ease-out;
  }
  .menu li {
    float: left;
    width: 100%;
  }
  .menu a {
    display: block;
    padding: 10px;
    font-size: 1.1em;
    text-align: center;
    background-color: #E8EEEA;
  }

  .menu-closed .menu {
    height: 0;
    padding: 0;
  }
}
.nav-bar {
  background-color: #F6F6F6;
}
.nav-bar__anchor {
  color: #4A4A4A;
  font-size: 15px;
  letter-spacing: 0.09px;
  line-height: 35px;
  padding: 7px 39px 7px 10px;
  display: inline-block;
}
.nav-bar__links-wrapper {
  float: right;
}
.nav-bar__link {
  color: #fff;
  font-size: 15px;
  letter-spacing: 0.09px;
  line-height: 35px;
  padding: 7px 35px 7px 35px;
  display: inline-block;
  background-color: #46A014;
  border-left: solid 1px #fff;
}
.nav-bar__link:first-child {
  border-left: none;
}
.nav-bar .subnav {
  position: relative;
}
.nav-bar .subnav__button {
  border: none;
  background: #616365;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 3;
  display: flex;
  cursor: pointer;
  border-radius: 30px;
  display: inline-flex;
  padding: 1rem;
}
.nav-bar .subnav__button .icon {
  background-color: transparent;
  left: 5px;
}
.nav-bar .subnav__button .icon {
  fill: #fff;
}
.nav-bar .subnav__item {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
.nav-bar .subnav__link {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  background-color: red;
  padding: 0.5rem 1rem;
  border-radius: 30px;
}
.nav-bar .subnav__link--strong {
  font-weight: 700;
}
.nav-bar .subnav__icon {
  display: none;
}
.nav-bar .subnav__list {
  right: 1rem;
  bottom: 5rem;
  position: absolute;
  text-align: right;
}
@media (min-width: 680px) {
  .nav-bar .subnav__button {
    display: none;
  }
  .nav-bar .subnav__item {
    display: block;
    margin-top: -1px;
  }
  .nav-bar .subnav__list {
    position: absolute;
    right: auto;
    bottom: auto;
    text-align: left;
    padding-left: 0px;
    margin: 0;
  }
  .nav-bar .subnav__link {
    background-color: #46A014;
    padding: 7px 35px 7px 35px;
    border-radius: 0px;
    display: block;
  }
}
.nav-bar .subnavModal {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.nav-bar .subnavModal .nav-bar__link {
  display: none;
}
.nav-bar .subnavModal.is-open {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
}
.nav-bar .subnavModal__button {
  border: none;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #616365;
  display: flex;
  border-radius: 30px;
  display: inline-flex;
  padding: 1rem;
}
.nav-bar .subnavModal__button .icon {
  background-color: transparent;
  left: 5px;
}
@media (min-width: 680px) {
  .nav-bar .subnavModal {
    position: relative;
    visibility: visible;
    float: right;
  }
  .nav-bar .subnavModal .nav-bar__link {
    display: block;
  }
  .nav-bar .subnavModal.is-open {
    background-color: transparent;
    position: relative;
  }
  .nav-bar .subnavModal__button {
    display: none;
  }
}

.page-nav {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
}
.page-nav li {
  margin: 0;
  padding: 5px 0;
  border-top: 1px solid #E8EEEA;
}
.page-nav li:first-child {
  border: 0;
}
.page-nav a {
  display: block;
  font-size: 1.4em;
  line-height: 1.3em;
  font-weight: 500;
  text-decoration: none;
  color: #ABABAB;
}
.page-nav a:hover {
  color: #636864;
}
.page-nav .active a, .page-nav .btn-sections a {
  color: #636864;
}

@media (min-width: 1024px) {
  .page-nav a {
    font-size: 1.8em;
  }

  .page-nav li.btn-sections {
    display: none;
  }
}
@media (min-width: 1360px) and (min-height: 740px) {
  .col4 .sticky {
    position: fixed;
    top: 20px;
    width: 370px;
    margin: 0 0 0 -585px;
    left: 50%;
  }

  .col3 .sticky {
    position: fixed;
    top: 20px;
    width: 270px;
    margin: 0 0 0 -585px;
    left: 50%;
  }

  .col2 .sticky {
    position: fixed;
    top: 20px;
    width: 170px;
    margin: 0 0 0 -585px;
    left: 50%;
  }

  .removeSticky {
    position: relative;
  }
  .removeSticky .col2 .sticky, .removeSticky .col3 .sticky, .removeSticky .col4 .sticky {
    position: absolute;
    top: auto;
    bottom: 0;
  }
}
@media (min-width: 680px) and (max-width: 1023px) {
  .page-nav li.btn-sections {
    display: none;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .page-nav {
    border-bottom: 1px solid #E8EEEA;
  }
  .page-nav li {
    display: none;
  }
  .page-nav li.btn-sections {
    display: block;
    position: relative;
  }
  .page-nav li.btn-sections:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: 16px;
    right: 10px;
    border-top: 6px solid #ABABAB;
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
  }
  .page-nav.opened li {
    display: block;
  }
  .page-nav.opened .btn-sections:after {
    top: 10px;
    border-top: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ABABAB;
    border-left: 6px solid transparent;
  }
}
@media (min-width: 680px) {
  .page-nav--mobile-only {
    display: none;
  }
}
.content {
  padding: 2em 0;
}

.section-btm-box .col12 {
  background-color: #f9f9f9;
  padding-top: 3em;
  padding-bottom: 3em;
}

@media (min-width: 680px) and (max-width: 1023px) {
  .section-btm-box .col12 .container .col7 {
    margin: 0 5%;
    width: 90%;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .content {
    padding: 10px 0;
  }
}
.banner {
  background-color: #C1C6C2;
  background-position: 50%;
  background-size: cover;
  padding: 50px 0;
  height: 280px;
}
.banner.banner-video {
  background-color: unset;
  background-image: unset;
  position: relative;
}
.banner video {
    width: 100%;
    height: 100%;
    transform: translateY(-50px);
    position: absolute;
    z-index: -1;
    object-fit: cover;
}
.banner .box {
  margin: 0;
  margin-top: 40px;
}
.banner .box.box-black h1, .banner .box.box-black h2 {
  font-size: 2em;
  color: #fff;
  margin: 0;
}
.banner .box.box-black .hero {
  color: #fff;
  margin: 0;
}
.banner .box.box-black a {
  color: #fff;
}

.page-home .banner {
  background-size: cover;
  position: relative;
}
.page-home .banner .box.box-black .hero {
  margin-bottom: 1em;
}

.page-our-people .banner .box {
  margin-top: 70px;
}

.page-contact-us .banner .box {
  margin-top: 70px;
}

.page-media-centre .banner .box {
  margin-top: 20px;
}

.page-media-centre-news .banner .box {
  margin-top: 30px;
}

@media (min-width: 1024px) {
  .page-home .banner {
    height: 470px;
  }
  .page-home .banner .col6 {
    width: 380px !important;
  }
  .page-home .banner .box {
    margin-top: 340px;
  }
}
@media (min-width: 1360px) {
  .page-home .banner .col6 {
    width: 470px !important;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .banner {
    padding: 20px 0;
    height: 200px;
  }
  .banner .box.box-black h1, .banner .box.box-black h2 {
    font-size: 1.7em;
  }

  .banner-profile {
    background-position: 85% 50%;
  }
  .banner-profile .box {
    margin-top: 125px;
  }

  .page-media-centre .banner .box {
    margin-top: 0;
  }
}
div.fc_card-container img {
  position: static !important;
}

/* === CARD CONTAINER === */
div.card-container {
  position: relative;
  display: block;
  padding: 0;
  -ms-perspective: 1000px;
  -o-perspective: 1000px;
  perspective: 1000px;
}

/* === CARD === */
.card-container .card {
  border-radius: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: inline-block;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  transition: transform 0.7s;
  transform-style: preserve-3d;
}

/* === STYLE FOR THE FRONT & BACK SIDE === */
.card-container .card > div {
  border-radius: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  background: #fff;
  margin: 0;
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* === BEGGINING EFFECT === */
.card-container .card[data-direction=top] .front, .card-container .card[data-direction=bottom] .front {
  transform: rotateX(0deg);
}

.card-container .card[data-direction=right] .front, .card-container .card[data-direction=left] .front {
  transform: rotateY(0deg);
}

.card-container .card[data-direction=top] .back, .card-container .card[data-direction=bottom] .back {
  transform: rotateX(180deg);
}

.card-container .card[data-direction=right] .back, .card-container .card[data-direction=left] .back {
  transform: rotateY(180deg);
}

/* === EFFECT DIRECTIONS === */
.flipping-right {
  transform: rotateY(180deg);
}

.flipping-left {
  transform: rotateY(-180deg);
}

.flipping-top {
  transform: rotateX(180deg);
}

.flipping-bottom {
  transform: rotateX(-180deg);
}

.noCSS3Container {
  -o-perspective: none !important;
  -ms-perspective: none !important;
  perspective: none !important;
}

.noCSS3Card {
  transition: none !important;
  transform-style: none !important;
}

.noCSS3Sides {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  transform: none !important;
}

@media (min-width: 1024px) {
  .card-container {
    width: 220px;
    height: 220px;
    float: left;
    margin: 0 10px 20px 10px;
  }
}
@media (min-width: 1360px) {
  .card-container {
    width: 270px;
    height: 270px;
    float: left;
    margin: 0 15px 30px 15px;
  }
}
@media (min-width: 680px) and (max-width: 1023px) {
  .cards-container {
    height: 480px;
  }

  .card-container {
    width: 48%;
    height: auto;
    float: left;
    margin: 0 10px 20px 10px;
  }
}
@media (min-width: 1024px) {
  .news-container {
    height: 480px;
  }
  .news-container .card-container {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .news-container .featured {
    height: 220px;
  }
  .news-container .featured .box {
    height: 180px;
  }
}
@media (min-width: 1360px) {
  .news-container .card-container {
    height: 270px;
    margin: 0 0 30px 0;
  }
  .news-container .featured {
    height: 270px;
  }
  .news-container .featured .box {
    height: 230px;
  }
}
@media (min-width: 680px) and (max-width: 1023px) {
  .news-container {
    height: 430px;
  }
  .news-container .card-container {
    width: 100%;
    height: 200px;
    margin: 0 0 10px 0;
  }
  .news-container .featured .box {
    padding: 10px;
    height: 200px;
  }

  .home-content {
    max-width: 860px;
    margin: 0 auto;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .news-container {
    height: 420px;
  }
  .news-container .card-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin: 0 0 20px 0;
  }
  .news-container .card-container:first-child {
    top: 0;
  }
  .news-container .featured .box {
    height: 200px;
  }

  .m-t-10 a {
    margin-top: 10px;
  }
}
.form-contact {
  margin: 0 0 20px 0;
}

.locations-list {
  margin: 0 0 20px 0;
}
.locations-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.locations-list .sflistitem {
  margin: 0 0 10px 0;
  background-color: #E8EEEA;
}
.locations-list .sflistitem > a {
  display: block;
  padding: 10px;
  text-decoration: none;
}
.locations-list h3 {
  margin: 0;
  float: left;
}
.locations-list h4 {
  clear: both;
  line-height: 1.2em;
}
.locations-list img {
  display: block;
  width: 120px;
  height: 120px;
}
.locations-list .staff-job {
  font-size: 0.9em;
  line-height: 1.2em;
}
.locations-list .location-lnk {
  float: right;
}
.locations-list .office-contact-details {
  padding: 10px;
}
.locations-list .office-staff {
  margin: 0 0 20px 0;
  position: relative;
}
.locations-list .office-staff h4 {
  margin: 0;
}
.locations-list .office-staff p {
  margin: 0;
}
.locations-list .profile-social {
  width: 120px;
  height: 50px;
  zoom: 0.7;
}
.locations-list .profile-social li a, .locations-list .profile-social .sflistitem a {
  padding: 0;
}

.locations-list .sflistitem {
  display: none;
}
.locations-list .sfChoiceContent {
  display: none;
}

.region-Scotland .Scotland {
  display: block;
}

.region-Northern .Northern {
  display: block;
}

.region-Western .Western {
  display: block;
}

.region-Central .Central {
  display: block;
}

.region-London .London {
  display: block;
}

.region-Southern .Southern {
  display: block;
}

.region-UK .UK {
  display: block;
}

@media (min-width: 1024px) {
  .form-contact {
    padding-right: 40px;
  }

  .locations-list .office-staff {
    width: 140px;
    height: 286px;
    float: left;
    margin: 0 0 20px 10px;
    position: relative;
  }
  .locations-list .office-staff .social {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .region-Scotland .Scotland {
    display: block;
  }

  .mobile-msg {
    display: none;
  }
}
@media (min-width: 1360px) {
  .form-contact {
    padding-right: 60px;
  }

  .locations-list .office-staff {
    width: 170px;
  }
  .locations-list .office-staff img {
    width: 140px;
    height: 140px;
  }
}
@media (min-width: 680px) and (max-width: 1023px) {
  .locations-list .office-staff {
    width: 50%;
    height: 180px;
    float: left;
    margin: 0 0 10px 0;
    padding: 10px 10px 10px 110px;
    position: relative;
    box-sizing: border-box;
  }
  .locations-list .office-staff img {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 15px;
    left: 10px;
  }

  .page-contact-us .sf_colsOut.sf_2cols_2_25.col4,
.page-contact-us .sf_colsOut.sf_2cols_2_50.col6 {
    width: 100%;
    float: none;
  }
  .page-contact-us .sf_colsOut.sf_2cols_1_50.col6 {
    display: none;
  }

  .desktop-msg {
    display: none;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .locations-list {
    padding: 10px 0;
  }
  .locations-list .office-staff {
    width: auto;
    height: auto;
    float: none;
    margin: 0;
    padding: 10px 10px 10px 110px;
    position: relative;
  }
  .locations-list .office-staff img {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 15px;
    left: 10px;
  }

  .desktop-msg {
    display: none;
  }
}
.contacts-list {
  margin-top: 2rem;
}
.contacts-list__wrapper {
  margin: 0 -15px;
}
.contacts-list img {
  border-radius: 50%;
}
.contacts-list span {
  display: block;
  font-size: 15px;
}
.contacts-list__name {
  font-weight: bold;
}

#regionsmap {
  width: 400px;
  margin: 0 auto;
}

@media (min-width: 120px) and (max-width: 1023px) {
  #regionsmap {
    display: none;
  }
}

.map-region {
	fill: #2fb457;
	stroke: #ffffff;
}

	.map-region:hover,
	.map-region.is-active {
		fill: #8fcd94;
		cursor: pointer;
	}

.btn, input.action, .sfsearchSubmit {
  display: inline-block;
  zoom: 1;
  padding: 10px 20px;
  font-size: 0.7em;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.4em;
  color: #fff;
  text-decoration: none;
  margin: 0;
  background-color: #ABABAB;
  border-radius: 4px;
  border: 2px solid #ABABAB;
  cursor: pointer;
}
.btn:hover, input.action:hover, .sfsearchSubmit:hover {
  text-decoration: none;
  color: #fff;
  border-color: #b8b8b8;
  background-color: #b8b8b8;
}
.btn:visited, input.action:visited, .sfsearchSubmit:visited {
  color: #fff;
}

button.btn {
  line-height: 1em;
  padding: 15px 20px;
  height: 42px;
}

.lnk {
  display: inline-block;
  zoom: 1;
  padding: 10px 0;
  font-weight: 300;
  line-height: 1em;
  color: #FF6602;
  text-decoration: none;
  margin: 0;
  border-radius: 4px;
  cursor: pointer;
  height: 14px;
}
.lnk:hover {
  text-decoration: none;
  color: #FF6602;
}

.lnk-download {
  position: relative;
  padding-left: 28px;
}

.btn-large {
  padding: 15px 30px;
  font-size: 1em;
}

.btn-small {
  padding: 5px 10px;
  font-size: 0.8em;
}

.btn-full {
  width: 100%;
  margin-bottom: 5px;
  display: block;
  box-sizing: border-box;
}

.btn-cta {
  color: #fff;
  background-color: #00B21D;
  border: 2px solid #00B21D;
}
.btn-cta:hover {
  color: #fff;
  border: 2px solid #00a81b;
  background-color: #00a81b;
}
.btn-cta:visited {
  color: #fff;
}

.btn-icon-left {
  position: relative;
  padding-left: 43px;
}

.btn-icon-right {
  position: relative;
  padding-right: 43px;
}

.icon {
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin: -12px 9px 0 0;
  top: 50%;
  left: 9px;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-color: rgba(0, 0, 0, 0.2);
}

.icon-right {
  left: auto;
  right: 9px;
  margin: -12px 0 0 9px;
}

.icon-arrow-left {
  background-position: -144px 0;
}

.icon-arrow-right {
  background-position: 0 0;
}

.icon-arrow-down {
  background-position: -72px 0;
}

.icon-arrow-up {
  background-position: -217px 0;
}

.icon-orange {
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: -4px 0 0 10px;
  top: 10px;
  right: 9px;
  background-color: #FF6602;
}

.icon-down {
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin: 0;
  top: 7px;
  left: 5px;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-position: -110px 0;
  background-color: transparent;
}

.tags {
  font-size: 1.1em;
  margin: 0;
  padding: 20px 0 0 0;
}
.tags .tag {
  font-size: 0.8em;
  display: inline-block;
  line-height: 22px;
  height: 24px;
  border-radius: 10px;
  padding: 0 12px;
  color: #666666;
  text-transform: capitalize;
  text-decoration: none;
  background-color: #d2d2d2;
}
.tags .tag:hover {
  background-color: #dedede;
}
.tags .tag-cta {
  background-color: #00B21D;
  color: #fff;
}
.tags .tag-cta:hover {
  background-color: #009919;
}

.sfTagsList {
  font-size: 1.1em;
  width: 100%;
  padding: 0 20px 10px 20px;
  margin: 0 0 10px -20px;
  border-bottom: 1px solid #dedede;
}
.sfTagsList li {
  display: inline;
}
.sfTagsList span {
  font-size: 0.8em;
  display: inline-block;
  line-height: 22px;
  height: 24px;
  border-radius: 10px;
  padding: 0 12px;
  text-decoration: none;
  background-color: #d2d2d2;
}

.featured {
  margin: 0 0 20px 0;
  display: block;
  text-decoration: none;
  color: #666666;
}
.featured .avatar {
  float: left;
}
.featured .box {
  position: relative;
}
.featured .box.box-grey:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #F6F6F6;
  border-left: 15px solid transparent;
  top: 0;
  left: 0;
  z-index: -1;
  margin: -15px 0 0 -15px;
}

@media (min-width: 1024px) {
  .featured {
    margin: 0 0 30px 0;
  }
  .featured .avatar {
    width: 100px;
  }
  .featured .lnk {
    position: absolute;
    bottom: 10px;
    left: 20px;
  }
  .featured .box {
    min-height: 100px;
  }

  .no-av .box {
    margin-left: 0;
    padding: 10px;
    min-height: 0;
  }
}
@media (min-width: 1360px) {
  .featured {
    margin: 0 0 30px 0;
  }
  .featured .avatar {
    width: 120px;
  }
  .featured .box {
    min-height: 80px;
  }

  .no-av .box {
    margin-left: 0;
    padding: 10px;
    min-height: 0;
  }
}
@media (min-width: 680px) and (max-width: 1023px) {
  .featured {
    height: auto;
    margin: 0;
    position: relative;
    background: #fff;
  }
  .featured .avatar {
    width: 80px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
  }
  .featured .box {
    width: 100%;
    min-height: 140px;
    padding: 10px 10px 1px 100px;
    box-sizing: border-box;
  }
  .featured .box h4 {
    margin: 0;
  }

  .no-av .box {
    padding: 10px;
    min-height: 0;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .featured {
    height: auto;
    margin: 0;
    position: relative;
    background: #fff;
  }
  .featured .avatar {
    width: 60px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
  }
  .featured .box {
    width: 100%;
    min-height: 80px;
    padding: 10px 10px 1px 10px;
    box-sizing: border-box;
  }

  .no-av .box {
    padding: 10px;
    min-height: 0;
  }
}
.box {
  margin: 0 0 20px 0;
  padding: 20px;
}

.box-grey {
  background-color: #F6F6F6;
}

.box-black {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}

.box-green {
  background-color: rgba(0, 178, 29, 0.8);
}
.box-green h1, .box-green .hero {
  color: #fff;
  margin: 0;
}

@media (min-width: 1360px) {
  .box {
    margin: 0 0 30px 0;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .box {
    margin: 0 0 10px 0;
    padding: 10px;
  }

  .box-green {
    padding: 6px;
  }
  .box-green #bannertitle {
    font-size: 1.6em;
  }
  .box-green #bannerdesc {
    font-size: 1em;
  }
}
.profile-list {
  display: block;
  text-decoration: none;
}
.profile-list .box {
  padding: 10px;
  height: 80px;
}
.profile-list h3 {
  margin: 0;
}
.profile-list p {
  color: #666666;
}
.profile-list img {
  display: block;
}
.profile-list:hover img {
  opacity: 0.8;
}
.profile-list:hover h3 {
  color: #FF6602;
}

@media (min-width: 1024px) {
  .profile-list .box {
    padding: 15px;
    height: 80px;
  }
  .profile-list .img {
    height: 220px;
  }
}
@media (min-width: 1360px) {
  .profile-list .img {
    height: 270px;
  }
}
@media (min-width: 680px) and (max-width: 1023px) {
  .profile-list .box {
    height: 100px;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .profile-list {
    max-width: 270px;
    margin-right: auto;
    margin-left: auto;
  }
}
.slide {
  position: relative;
}
.slide .caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}
.slide .caption p {
  margin: 0;
  padding: 10px;
}

@media (min-width: 1360px) {
  .slide {
    width: 1170px;
    height: 660px;
    overflow: hidden;
  }
}
@media (min-width: 1024px) {
  .slide {
    width: 940px;
    height: 530px;
    overflow: hidden;
  }
}
@media (min-width: 120px) and (max-width: 1023px) {
  .project-gallery .col12 {
    padding: 0;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-loading .slick-list {
  background: #fff url("./../img/ajax-loader.gif") center center no-repeat;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-track,
.slick-slide,
.slick-slide img {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  zoom: 1;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 37px;
  width: 36px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -18px;
  padding: 8px;
  border: none;
  outline: none;
  background-color: #929292;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
}

.slick-prev {
  left: -36px;
  border-radius: 4px 0 0 4px;
}
.slick-prev:before {
  content: "";
  display: block;
  width: 21px;
  height: 21px;
  border-radius: 11px;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-position: -146px -1px;
  background-color: #787878;
}

.slick-next {
  right: -36px;
  border-radius: 0 4px 4px 0;
}
.slick-next:before {
  content: "";
  display: block;
  width: 21px;
  height: 21px;
  border-radius: 11px;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-position: -1px -1px;
  background-color: #787878;
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

@media (min-width: 1024px) {
  .slick-slide {
    width: 940px;
    height: 530px;
    overflow: hidden;
  }
}
@media (min-width: 1360px) {
  .slick-slide {
    width: 1170px;
    height: 660px;
  }
}
@media (min-width: 120px) and (max-width: 1023px) {
  .slick-prev {
    left: 0;
    border-radius: 0 4px 4px 0;
  }

  .slick-next {
    right: 0;
    border-radius: 4px 0 0 4px;
  }
}
.case-study {
  display: block;
  margin: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
}
.case-study img {
  display: block;
}
.case-study .title {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: rgba(0, 178, 29, 0.7);
  box-sizing: border-box;
}
.case-study h3 {
  margin: 0;
  color: #fff;
}

.case-study-list {
  display: block;
  max-width: 270px;
  position: relative;
  margin: 0 auto 20px auto;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
}
.case-study-list img {
  display: block;
}
.case-study-list .title {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: rgba(0, 178, 29, 0.7);
  box-sizing: border-box;
}
.case-study-list h3 {
  margin: 0;
  color: #fff;
  font-size: 0.9em;
}

.project-gallery img {
  display: block;
}

.project-details {
  background-color: #EBEBEB;
  margin-bottom: 20px;
  padding: 1.5em;
}
.project-details ul {
  margin: 0 0 40px 0;
  list-style: none;
  padding: 0;
}
.project-details li {
  margin: 0 0 10px 0;
  padding: 0 0 0 30px;
  position: relative;
}
.project-details li:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-repeat: no-repeat;
  background-position: -42px 0;
}
.project-details .sfdownloadFileWrp {
  padding: 0 0 20px 0;
}
.project-details .sfdownloadFileWrp a.sfpdf {
  text-transform: uppercase;
  color: #000;
  text-decoration: underline;
  line-height: 1.2em;
  font-size: 0.8em;
  padding-left: 28px;
  position: relative;
}
.project-details .sfdownloadFileWrp a.sfpdf:hover {
  color: #FF6602;
}
.project-details .sfdownloadFileWrp a.sfpdf:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-repeat: no-repeat;
  background-position: -90px -3px;
}
.project-details p:last-child {
  margin: 0;
}

figure {
  margin: 0 0 20px 0;
  padding: 0;
}

figcaption {
  font-style: italic;
  font-size: 0.8em;
  line-height: 1.1em;
}

.pull-quote {
  display: block;
  font-size: 1.8em;
  line-height: 1.2em;
  font-weight: 600;
  position: relative;
}
.pull-quote::before {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  width: 30px;
  height: 20px;
}
.pull-quote::after {
  content: "";
}

.Text_with_left_pull_quote .pull-quote {
  padding: 0 60px 0 0;
  text-align: right;
}
.Text_with_left_pull_quote .pull-quote::before {
  right: 20px;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-position: 0 -50px;
}

.Text_with_right_pull_quote .pull-quote {
  padding: 0 0 0 60px;
  text-align: left;
}
.Text_with_right_pull_quote .pull-quote::before {
  left: 20px;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-position: 0 -24px;
}

.project-facts {
  padding: 3em 0;
  background-color: #F6F6F6;
}

.fact {
  padding: 0 20px;
}
.fact .number {
  display: block;
  font-size: 2.2em;
  font-weight: bold;
}
.fact .type {
  display: block;
  height: 32px;
  line-height: 32px;
  padding: 5px 0 5px 40px;
  font-size: 1.8em;
  border-bottom: 1px solid #C1C6C2;
  position: relative;
}
.fact .type img {
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 32px;
  height: 32px;
}
.fact .legend {
  display: block;
  font-size: 1em;
  color: #ABABAB;
}

.quote {
  background-color: #EBEBEB;
  padding: 2em;
  position: relative;
}
.quote:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #EBEBEB;
  border-left: 15px solid transparent;
  top: 0;
  left: 0;
  margin: -15px 0 0 -15px;
}
.quote.news-quote .img {
  position: absolute;
  left: -90px;
  top: 0;
}
.quote.news-quote .img img {
  width: 70px;
}
.quote .img img {
  display: block;
}
.quote .quote-auth-name {
  margin: 0;
}
.quote .quote-auth-title {
  margin: 0;
  color: #ABABAB;
}
.quote .quote-text {
  font-style: italic;
  color: #000;
}

.project-links {
  padding: 2em;
  background-color: #E8EEEA;
}
.project-links ul {
  padding: 0;
  margin: 0 0 20px 0;
  list-style: none;
}
.project-links ul h3, .project-links ul p {
  margin: 0 0 5px 0;
}
.project-links li {
  margin: 0 0 20px 0;
  padding: 0 0 0 50px;
  position: relative;
}
.project-links li:before {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 20px;
  top: 8px;
  left: 0;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-position: 0 -24px;
  background-repeat: no-repeat;
}
.project-links .project-link {
  margin: 0;
  padding: 0;
}

.project-share {
  background-color: #F6F6F6;
  padding: 1.5em;
}

.share-download p {
  margin: 0 0 5px 0;
}
.share-download .link {
  color: #C1C6C2;
}

.share-social .social {
  width: 208px;
  height: 60px;
}
.share-social .social .btn-linkedin a {
  background-position: -50px -150px;
}
.share-social .social .btn-twitter a {
  background-position: -150px -150px;
}
.share-social .social .btn-facebook a {
  background-position: -50px -200px;
}
.share-social .social .btn-instagram a {
  background-position: -150px -200px;
}

.item img {
  display: block;
}
.item .tags {
  width: 100%;
  padding: 0 20px 10px 20px;
  margin: 0 0 10px -20px;
  border-bottom: 1px solid #dedede;
}

@media (min-width: 1024px) {
  .cards-container {
    height: 480px;
  }
  .cards-container.one-row {
    height: 260px;
  }

  .project-intro {
    padding: 1.5em 3em 1.5em 0;
  }

  .quote {
    padding: 3em;
    max-width: 780px;
    margin: 0 auto 20px auto;
  }
  .quote .quote-cont {
    float: left;
  }
  .quote img {
    float: left;
    margin: 0 2em 0 0;
  }

  .case-study .title, .case-study-list .title {
    bottom: -100%;
    transition: all ease-out 100ms;
  }
  .case-study:hover .title, .case-study-list:hover .title {
    bottom: 0;
  }

  .project-links {
    padding: 3em;
  }
  .project-links h2 {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
  .project-links ul {
    max-width: 640px;
    margin: 0 auto;
  }

  .share-download {
    width: 35%;
    padding-left: 15%;
    float: left;
  }

  .share-social {
    width: 45%;
    padding-left: 5%;
    float: left;
  }

  .page-how-we-do-it .masonry {
    margin-left: -10px;
  }
}
@media (min-width: 1360px) {
  .case-study {
    margin: 0 0 30px 0;
  }

  .cards-container {
    height: 580px;
  }
  .cards-container.one-row {
    height: 290px;
  }

  .quote {
    max-width: 970px;
  }

  .page-how-we-do-it .masonry {
    margin-left: -15px;
  }

  .case-study-list .title {
    bottom: -100%;
    transition: all ease-out 100ms;
  }
  .case-study-list:hover .title {
    bottom: 0;
  }
}
@media (min-width: 680px) and (max-width: 1023px) {
  .container .container.cards-container {
    width: 100%;
    max-width: 540px;
    height: 0;
    padding-bottom: 100%;
    margin: 0 auto 20px auto;
    position: relative;
  }

  .container .cards-container .card-container {
    width: 50%;
    float: left;
    height: 0;
    padding-bottom: 50%;
    margin: 0;
  }
  .container .cards-container .card-container .title {
    padding: 8px;
  }

  .project-intro {
    padding: 1.5em 3em 1.5em 0;
  }

  .quote .img {
    float: left;
    width: 25%;
  }
  .quote .quote-cont {
    float: left;
    width: 75%;
  }
  .quote .quote-cont .quote-text, .quote .quote-cont .quote-author {
    padding-left: 1em;
  }

  .fact {
    min-height: 120px;
    margin-bottom: 20px;
  }

  .share-download {
    width: 40%;
    padding: 0 4%;
    float: left;
  }

  .share-social {
    width: 40%;
    padding: 0 4%;
    float: left;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .container .container.cards-container {
    width: 100%;
    max-width: 540px;
    height: 0;
    padding-bottom: 100%;
    margin: 0 auto 20px auto;
    position: relative;
  }

  .container .cards-container .card-container {
    width: 50%;
    float: left;
    height: 50%;
    padding-bottom: 50%;
    margin: 0;
  }
  .container .cards-container .card-container .title {
    padding: 8px;
  }

  .card .case-study img {
    width: 100%;
  }

  .quote img {
    display: block;
    margin: 0 auto 20px auto;
  }

  .fact {
    margin-bottom: 20px;
  }

  .project-gallery {
    margin-bottom: 1em;
  }
}
@media (min-width: 400px) and (max-width: 679px) {
  .item-half-small {
    width: 50%;
    float: left;
  }
  .item-half-small .box {
    margin: 0 0 20px 0;
  }

  .sfContentBlock {
    clear: both;
  }
}
.case-study-list__wrapper {
  margin: 0 -15px;
}

.tag-filters-wrap {
  background-color: #F6F6F6;
  margin-bottom: 40px;
}
.tag-filters-wrap::after {
  content: "";
  display: block;
  clear: both;
}
.tag-filters-wrap .tag-filter-side {
  width: 33%;
  padding: 20px;
  float: left;
  box-sizing: border-box;
}
.tag-filters-wrap .tag-filter-side ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tag-filters-wrap .tag-filter-side .sftaxonItem {
  display: inline;
}
.tag-filters-wrap .tag-filter-side .sftaxonItem a {
  font-size: 0.8em;
  display: inline-block;
  line-height: 22px;
  height: 24px;
  border-radius: 10px;
  padding: 0 12px;
  text-decoration: none;
  color: #666666;
  background-color: #d2d2d2;
  margin: 0 4px 4px 0;
}
.tag-filters-wrap .tag-filter-side .sftaxonItem a:hover {
  background-color: #dedede;
}
.tag-filters-wrap .tag-filter-side .sftaxonItem .active {
  color: #fff;
  background-color: #00B21D;
}
.tag-filters-wrap .tag-filter-side .sftaxonItem .active:hover {
  color: #fff;
  background-color: #00c11f;
}
.tag-filters-wrap .tag-filter-side h2 {
  font-weight: 300;
  font-size: 1.2em;
  margin: 0 0 1em 0;
  color: #212121;
}

.tag-filters {
  position: relative;
}
.tag-filters:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 100px;
  width: 1px;
  height: 1px;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #F6F6F6;
  border-left: 15px solid transparent;
  margin: -30px 0 0 0;
}
.tag-filters h4 {
  margin: 0;
}
.tag-filters .box {
  margin: 0;
}
.tag-filters.collapsed {
  overflow: hidden;
  height: 0;
}
.tag-filters .alpha {
  border-right: 1px solid #e9e9e9;
}

.item .img img {
  width: 100%;
  height: auto;
}
.item .img a:hover img {
  opacity: 0.8;
}

@media (min-width: 680px) and (max-width: 1023px) {
  .content-how {
    padding: 0 10px;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .content-how {
    padding: 0 10px;
  }

  .tag-filters-wrap .tag-filter-side {
    width: 100%;
    padding: 10px 0;
  }

  .page-how-we-do-it .item .img img {
    width: 100%;
  }

  .sfTagsList {
    box-sizing: border-box;
    margin-left: 0;
  }

  #related p.txtr {
    text-align: left;
  }

  .btn-tag-filters {
    width: 100%;
    text-align: left;
    box-sizing: border-box;
  }
}
.main-content blockquote, .main-content .blockquote {
  background-color: #F6F6F6;
  color: #666666;
  margin: 10px auto 20px auto;
  padding: 15px;
  position: relative;
}
.main-content blockquote p, .main-content .blockquote p {
  font-style: italic;
}
.main-content blockquote:before, .main-content .blockquote:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #F6F6F6;
  border-left: 15px solid transparent;
  top: 0;
  left: 0;
  margin: -15px 0 0 -15px;
}
.main-content blockquote .quote-text, .main-content .blockquote .quote-text {
  font-style: italic;
  color: #000;
}
.main-content blockquote .sfImageWrapper img, .main-content .blockquote .sfImageWrapper img {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -100px;
}
.main-content blockquote.bq-w-img, .main-content .blockquote.bq-w-img {
  margin-left: 100px;
}

.list-article {
  background-color: #F6F6F6;
  margin: 0 0 20px 0;
  padding: 20px;
  position: relative;
}
.list-article:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #F6F6F6;
  border-left: 20px solid transparent;
  top: 0;
  left: 0;
  z-index: -1;
  margin: -20px 0 0 -20px;
}
.list-article a {
  text-decoration: none;
}
.list-article h3 {
  margin: 0;
}
.list-article .meta {
  font-size: 0.9em;
  color: gray;
}

.article-blog {
  position: relative;
  margin-left: 110px;
}
.article-blog .avatar {
  position: absolute;
  top: 0;
  left: -110px;
  width: 80px;
  height: 80px;
}

blockquote {
  margin: 20px 0;
}
blockquote img {
  float: left;
  margin: 0 20px 20px 0;
  width: 80px;
}

ul.blog-social, ul.news-social, ul.case-social {
  margin: 20px 0;
  width: auto;
}

.author-profile {
  position: relative;
}

.box-resources {
  background-color: #EBEBEB;
}
.box-resources h2 {
  margin: 0;
}
.box-resources .box {
  margin: 0;
}
.box-resources .box-grey {
  position: relative;
  padding-left: 120px;
  background-color: #dedede;
}
.box-resources .box-grey .sfImageWrapper {
  position: absolute;
  left: 10px;
}
.box-resources .box-grey img {
  width: 80px;
}

@media (min-width: 1024px) {
  .media-news-container .featured .box {
    height: 120px;
  }

  .list-article {
    padding: 20px 240px 20px 20px;
  }
  .list-article .img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 220px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .list-article .img img {
    opacity: 0;
  }

  .author-profile .avatar {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .author-profile .cont {
    margin-left: 140px;
  }

  .featured-media {
    position: relative;
    margin: 0 0 0 100px;
  }
  .featured-media .avatar {
    position: absolute;
    width: 80px;
    top: 0;
    left: 0;
    margin: 0 0 0 -100px;
  }
  .featured-media h4 {
    height: 50px;
    overflow: hidden;
  }
}
@media (min-width: 1360px) {
  .list-article {
    margin-bottom: 30px;
    padding: 30px 290px 30px 30px;
  }
  .list-article .img {
    width: 270px;
  }

  .col3.item .img img {
    width: 100%;
  }
}
@media (min-width: 680px) and (max-width: 1023px) {
  .list-article {
    padding: 20px;
  }
  .list-article .img {
    display: none;
  }

  .box-resources,
.box-resources-wrap {
    padding: 0;
    margin: 0;
  }
  .box-resources .container .col12,
.box-resources-wrap .container .col12 {
    padding: 0;
    margin: 0;
  }

  .featured-media {
    position: relative;
    margin: 0;
  }
  .featured-media .avatar {
    position: absolute;
    width: 60px;
    top: 0;
    left: 0;
    margin: 10px 0 0 10px;
  }

  .sfContentBlock {
    clear: both;
  }
}
@media (min-width: 120px) and (max-width: 679px) {
  .list-article {
    padding: 10px;
    margin-bottom: 10px;
  }
  .list-article .img {
    display: none;
  }

  .article-blog {
    position: relative;
    margin-left: 0;
  }
  .article-blog .avatar {
    display: none;
  }

  .box-resources,
.box-resources-wrap {
    padding: 0;
    margin: 0;
  }
  .box-resources .container .col12,
.box-resources-wrap .container .col12 {
    padding: 0;
    margin: 0;
  }
  .box-resources .container .col4,
.box-resources-wrap .container .col4 {
    padding: 0;
  }
  .box-resources .container .col4 .box,
.box-resources-wrap .container .col4 .box {
    padding: 10px 20px;
  }

  .featured-media {
    position: relative;
    margin: 0 0 0 70px;
  }
  .featured-media .avatar {
    position: absolute;
    width: 60px;
    top: 0;
    left: 0;
    margin: 0 0 0 -80px;
  }

  .page-media-centre-resources .item .img img {
    width: 100%;
  }
}
.social-hub {
  position: relative;
  padding-left: 60px;
}

.social-tabs {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 60px;
}
.social-tabs li {
  margin: 0 0 1px 0;
}
.social-tabs li:last-child {
  margin: 0;
}
.social-tabs a {
  display: block;
  height: 60px;
  overflow: hidden;
  background-color: #EBEBEB;
  border-right: 6px solid #C1C6C2;
  text-indent: 200px;
  line-height: 200px;
  outline: 0;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-repeat: no-repeat;
}
.social-tabs a:hover {
  background-color: #f8f8f8;
}
.social-tabs .active a {
  border: none;
}
.social-tabs .active a:hover {
  background-color: #EBEBEB;
}

.btn-tab-twitter a {
  background-position: -200px -180px;
}

.btn-tab-facebook a {
  background-position: -200px -120px;
}

.btn-tab-google a {
  background-position: -200px -240px;
}

.tab-container {
  float: left;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  background-color: #EBEBEB;
  box-sizing: border-box;
}

.tab {
  display: none;
}
.tab.active {
  display: block;
}

.tab-cont {
  padding: 20px;
  float: left;
  background-color: #DEDDDD;
  box-sizing: border-box;
}
.tab-cont time {
  font-size: 0.9em;
  color: gray;
}

.tab-ctas .social-info {
  font-size: 1em;
  font-weight: 200;
}
.tab-ctas .social-info strong {
  font-size: 1.4em;
  font-weight: 200;
}

.tab-twitter .tab-cont a {
  color: #00aced;
}

.tab-facebook .tab-cont a {
  color: #3b5998;
}

.tab-google .tab-cont a {
  color: #dd4b39;
}

.tab-ctas .btn {
  color: #fff;
  font-size: 16px;
  text-transform: none;
  height: 18px;
}

.btn-social-twitter {
  background-color: #00aced;
  border-color: #00aced;
}
.btn-social-twitter:hover {
  background-color: #0099d4;
  border-color: #0099d4;
}

.btn-social-facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-social-facebook:hover {
  background-color: #344e86;
  border-color: #344e86;
}

.btn-social-google {
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-social-google:hover {
  background-color: #d73925;
  border-color: #d73925;
}

@media (min-width: 1024px) {
  .social-hub {
    height: 182px;
  }

  .social-tabs {
    height: 182px;
  }

  .tab-container {
    height: 182px;
  }

  .tab {
    height: 142px;
  }

  .tab-cont {
    width: 70%;
    height: 142px;
  }

  .tab-ctas {
    float: right;
    width: 28%;
  }
}
@media (min-width: 120px) and (max-width: 1023px) {
  .social-hub-wrap .col12 {
    padding: 0 2px;
  }

  .tab-cont {
    margin: 0 0 20px 0;
  }

  .tab-ctas {
    clear: both;
  }
  .tab-ctas .social-info {
    margin: 0;
  }
}
.foot-cont {
  background-color: #F6F6F6;
  color: #909090;
  font-size: 0.9em;
}
.foot-cont .box {
  padding: 20px;
}
.foot-cont a {
  color: #909090;
}
.foot-cont ul {
  margin: 0 0 20px 0;
  list-style: none;
  padding: 0;
}

.back-to-top {
  text-align: center;
  background-color: #C1C6C2;
  padding: 16px 0;
}
.back-to-top a {
  padding: 0 40px;
  position: relative;
  color: #fff;
}

.links {
  float: left;
  width: 48%;
  margin-left: 1%;
  margin-right: 1%;
}

.social {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 156px;
  height: 104px;
}
.social li {
  padding: 0 !important;
  float: left;
  margin: 0 2px 2px 0;
  background-color: #fdfdfd;
  background-image: none;
}
.social li::before {
  display: none !important;
}
.social a {
  display: block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  line-height: 200px;
  text-indent: 200px;
  background-image: url("../img/sprite-lr.png");
  background-size: 260px 300px;
  background-repeat: no-repeat;
}
.social.profile-social {
  width: 104px;
  height: 52px;
  zoom: 0.9;
}

.btn-linkedin a {
  background-position: 0 -150px;
}
.btn-linkedin a:hover {
  background-position: -50px -150px;
}

.btn-twitter a {
  background-position: -100px -150px;
}
.btn-twitter a:hover {
  background-position: -150px -150px;
}

.btn-facebook a {
  background-position: 0 -200px;
}
.btn-facebook a:hover {
  background-position: -50px -200px;
}

.btn-instagram a {
  background-position: -100px -200px;
}
.btn-instagram a:hover {
  background-position: -149px -200px;
}

.btn-youtube a {
  background-position: 0 -250px;
}
.btn-youtube a:hover {
  background-position: -50px -250px;
}

.btn-pinterest a {
  background-position: -100px -250px;
}
.btn-pinterest a:hover {
  background-position: -150px -250px;
}

.banner {
  height: 200px;
}

.box-black {
  background-color: #000;
}

.box-green {
  background-color: #00B21D;
}

.container .container.cards-container {
  width: 100%;
  max-width: 540px;
  height: 0;
  padding-bottom: 100%;
  margin: 0 auto 20px auto;
  position: relative;
}

.container .cards-container .card-container {
  width: 50%;
  float: left;
  height: 0;
  padding-bottom: 50%;
  margin: 0;
}
.container .cards-container .card-container .title {
  padding: 8px;
}

.project-intro {
  padding: 1.5em 3em 1.5em 0;
}

.quote .img {
  float: left;
  width: 25%;
}
.quote .quote-cont {
  float: left;
  width: 75%;
}
.quote .quote-cont .quote-text, .quote .quote-cont .quote-author {
  padding-left: 1em;
}

.fact {
  min-height: 120px;
  margin-bottom: 20px;
}

.project-gallery, .section-intro, .project-facts {
  clear: both;
}

.project-facts {
  height: 140px;
}

.project-share {
  margin: 0 0 30px 0;
}

.share-download {
  width: 40%;
  padding: 0 4%;
  float: left;
}

.share-social {
  width: 40%;
  padding: 0 4%;
  float: left;
}

.case-study .title, .case-study-list .title {
  background-color: #00B21D;
}
.case-study .img img, .case-study-list .img img {
  width: 220px;
  height: 220px;
}

.masonry {
  width: 960px;
  margin-left: -10px;
}

.form-contact {
  padding-right: 40px;
}

.location-content {
  overflow: hidden;
}

.locations-list {
  overflow: hidden;
}
.locations-list .office-staff {
  width: 140px;
  height: 286px;
  float: left;
  margin: 0 0 20px 10px;
  position: relative;
}
.locations-list .office-staff .social {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 120px;
  height: 50px;
}
.locations-list .office-staff .social li {
  display: inline-block;
  float: left;
}

.mobile-msg {
  display: none;
}

.locations-list .profile.social {
  zoom: 0.9;
}

.featured {
  margin: 0 0 30px 0;
}
.featured .avatar {
  width: 100px;
}
.featured .lnk {
  position: absolute;
  bottom: 10px;
  left: 20px;
}
.featured .box {
  min-height: 100px;
}

.no-av .box {
  margin-left: 0;
  padding: 10px;
  min-height: 0;
}

.card-container {
  width: 220px;
  height: 220px;
  float: left;
  margin: 0 10px 20px 10px;
}

.head {
  background-color: #fff !important;
  background-image: url("../img/bg-head.png");
  background-repeat: repeat-x;
  background-position: left top;
  background-size: 10px 70px;
}

.logo a {
  background-image: url("../img/logo-bam-lr.png");
}

.head {
  height: 35px;
  padding: 0;
  display: block;
}
.head .container {
  height: 70px;
}
.head .col12 {
  position: relative;
}

.page-home .banner {
  height: 370px;
}
.page-home .banner .col6 {
  width: 250px !important;
}
.page-home .banner .box {
  margin-top: 200px;
}

.news-container {
  height: 480px;
}
.news-container .card-container {
  width: 100%;
  margin: 0 0 20px 0;
}
.news-container .featured {
  height: 220px;
}
.news-container .featured .box {
  height: 180px;
}

.cards-container {
  width: 460px;
  height: 460px;
  margin-bottom: 20px;
}

.sfsearchBox input[type=text] {
  width: 340px;
}

.media-news-container .featured .box {
  height: 120px;
}

.list-article {
  padding: 20px 240px 20px 20px;
}
.list-article .img {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 220px;
  background-size: cover;
  background-repeat: no-repeat;
}
.list-article .img img {
  opacity: 0;
}

.author-profile .avatar {
  position: absolute;
  top: 20px;
  left: 20px;
}
.author-profile .cont {
  margin-left: 140px;
}

.featured-media {
  position: relative;
  margin: 0 0 0 100px;
}
.featured-media .avatar {
  position: absolute;
  width: 80px;
  top: 0;
  left: 0;
  margin: 0 0 0 -100px;
}

.masonry:after {
  clear: both;
  content: "";
  display: block;
}

.menu {
  float: right;
  transition: all 100ms linear;
}
.menu li {
  float: left;
  height: 70px;
  position: relative;
  transition: all 100ms linear;
}
.menu li:hover .submenu {
  display: block;
}
.menu a {
  height: 70px;
  line-height: 68px;
  padding: 0 15px;
  transition: all 100ms linear;
}

.submenu {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  height: auto;
  width: 200px;
  font-size: 0.9em;
  list-style: none;
}
.submenu li {
  margin: 0;
  padding: 0;
  height: auto;
  float: none;
}
.submenu a {
  display: block;
  height: auto;
  line-height: 20px;
  padding: 10px 15px;
  color: #666666;
  background-color: #F6F6F6;
}
.submenu a:hover {
  background-color: #E8EEEA;
  color: #00B21D;
}

.btn-menu {
  display: none;
}

.btn-search a {
  background-position: -104px -54px;
}

.page-nav a {
  font-size: 1.8em;
}

.page-nav li.btn-sections {
  display: none;
}

.profile-list .box {
  padding: 15px;
  height: 80px;
}
.profile-list .img img {
  width: 220px;
  height: 220px;
}

.container9 {
  width: 720px;
}

.slick-slide {
  width: 940px;
  height: 530px;
  overflow: hidden;
}

.slick-prev {
  left: -16px;
}

.slick-next {
  right: -16px;
}

.slide {
  width: 940px;
  height: 530px;
  overflow: hidden;
}

.social-hub {
  height: 182px;
}

.social-tabs {
  height: 182px;
}

.tab-container {
  height: 182px;
}

.tab {
  height: 142px;
}

.tab-cont {
  width: 70%;
  height: 142px;
}

.tab-ctas {
  float: right;
  width: 28%;
}

.content-timeline {
  width: 800px;
}
.content-timeline .item img {
  width: 100%;
  height: 200px;
}

.content-decade {
  width: 740px;
}

.top-search {
  position: absolute;
  top: 0;
  width: 0;
  right: 36px;
  padding: 18px 0;
  transition: all 120ms linear;
}
.top-search form {
  position: relative;
  width: 190px;
}
.top-search fieldset {
  height: 32px;
}

.search-open .top-search {
  width: 200px;
}
.search-open .btn-search a {
  background-position: -41px -54px;
}

#related .col12 {
  width: 960px;
  height: 250px;
  position: relative;
  overflow: hidden;
}
#related .cards-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 960px;
  max-width: 960px;
  height: 220px !important;
  max-height: 220px !important;
}
#related .card-container {
  width: 220px;
  height: 220px !important;
  float: left;
  margin-right: 20px;
  overflow: hidden;
}
#related .card-container .card, #related .card-container .front, #related .card-container .back, #related .card-container .case-study {
  width: 220px;
  height: 220px !important;
}

.pageNumbers a {
  display: inline-block;
  zoom: 1;
  padding: 5px 10px;
  font-size: 0.8em;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.4em;
  color: #fff;
  text-decoration: none;
  margin: 0 5px 5px 0;
  background-color: #ABABAB;
  border-radius: 4px;
  border: 2px solid #ABABAB;
  cursor: pointer;
}
.pageNumbers a:hover {
  text-decoration: none;
  color: #fff;
  border-color: #b8b8b8;
  background-color: #b8b8b8;
}
.pageNumbers a:visited {
  color: #fff;
}
.pageNumbers a.sf_PagerCurrent {
  color: #fff;
  border-color: #b8b8b8;
  background-color: #b8b8b8;
}

.subscription-form {
    background-color: #F6F6F6;
}

.subscription-form label {
    font-weight: 300;
}

.embedded-form form label, .form label {
    font-weight: 300;
}

.embedded-form__wrapper {
    background-color: #F6F6F6;
}